import { 
    getAuth, 
    //connectAuthEmulator, 
    onAuthStateChanged,
    signOut
} from "firebase/auth";
import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { firebaseApp } from "./firebase";
import { storage } from "./storage";

export function auth() {
    if (window.auth) {
        return window.auth;
    }

    const auth = getAuth(firebaseApp());
    //connectAuthEmulator(auth, "http://localhost:9099");

    onAuthStateChanged(auth, (user) => {
        if (user) {
            onLogin(user);
        } else {
            onLogout();
        }
    });

    window.auth = auth;

    return window.auth;
}

export function getCurrentUser() {
    return new Promise((resolve, reject) => {
       const unsubscribe = auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
       }, reject);
    });
}

export async function logout(redirect = true) {
    await signOut(auth());
    if (redirect) {
        router.navigate("/home");
    }
}

export async function setupUserAvatarMenu(user) {
    // setup avatar menu
    document.querySelector(".avatar-menu").style.visibility = "visible";
    document.querySelector(".avatar-menu .username").textContent = user.email;
    document.querySelector(".avatar-menu .avatar").textContent = "";
    document.querySelector(".avatar-menu .avatar").appendChild(await createUserProfileElm(user));
}

export async function createUserProfileElm(user) {
    let zoom = "100";
    let src = "../img/profile.png";
    try {
        const sref = ref(storage(), `${user.uid}/profile`);
        const metadata = await getMetadata(sref);
        const url = await getDownloadURL(sref);

        zoom = metadata?.customMetadata?.zoom || "100";
        src = url;

        return img;
    } catch (err) {
        console.debug("No photo, defaulting...");
    }

    const img = document.createElement("img");
    img.style.width = zoom + "%";
    img.style.height = zoom + "%";
    img.style.objectFit = "contain";
    img.src = src;

    return img;
}

const onLogin = async (user) => {
    const providerId = user.providerData[0].providerId;
    if (providerId == "password" && !user.emailVerified) {
        return
    }

    await setupUserAvatarMenu(user);

    //document.querySelector(".main-menu").style.display = "block";
}

const onLogout = () => {
    document.querySelector(".avatar-menu").style.visibility = "hidden";

    //document.querySelector(".main-menu").style.display = "none";
}