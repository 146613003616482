import { 
    getStorage, 
    //connectStorageEmulator,
    updateMetadata,
} from "firebase/storage";
import {
    ref, uploadBytes
} from 'firebase/storage';
import { firebaseApp } from "./firebase";

export function storage() {
    if (window.storage) {
        return window.storage;
    }

    const storage = getStorage(firebaseApp());
    //connectStorageEmulator(storage, "localhost", 9199);

    window.storage = storage;

    return window.storage;
}

export async function saveUserPhoto(user, photoFile, photoZoom) {
    const sref = ref(storage(), `${user.uid}/profile`);
    const metadata = {
        customMetadata: {
            'zoom': photoZoom || 100
        }
    }

    if (photoFile) {
        await uploadBytes(sref, photoFile, metadata);
    } else {
        await updateMetadata(sref, metadata);
    }
}
