import tpl from "./home.html";
import "./style.css";


import { getCurrentUser } from "../auth";
import { debounce } from "../common/common";

function render() {
    return "<home>" + tpl + "</home>";
}

async function mount() {
    const user = await getCurrentUser();

    if (user) {
        const providerId = user.providerData[0].providerId;
        // check email verification only for password type users
        if (providerId != "password" || (providerId == "password" && user.emailVerified)) {
            router.navigate("/items");
        }
    }

    setupSearch();

    document.querySelector("home button.start").addEventListener("click", () => {
        router.navigate('/auth');
    });
}

function setupSearch() {
    const old_search = document.querySelector("input.main-search-input");
    var search = old_search.cloneNode(true);
    old_search.parentNode.replaceChild(search, old_search);

    search.setAttribute("placeholder", "Search for influencers");

    search.parentNode.querySelector(".fa-times").addEventListener("click", (e) => {
        search.value = "";
        searchItems();
    });

    search.addEventListener('keyup', debounce(() => searchInfluencers()));
}

async function searchInfluencers() {
    const input = document.querySelector("input.main-search-input");
    const queryText = input.value;
    input.parentElement.setAttribute("loading", "true");

    try {
        if (queryText) {
            router.navigate(`/users?q=${queryText}`);
        } else {
            // clear search
            router.navigate("/home");
        }
    } catch(error) {
        console.log(error);
    } finally {
        input.parentElement.setAttribute("loading", "false");
    }
}

export {render, mount};