import { 
    getFirestore,
    //connectFirestoreEmulator,
    collection, doc
} from "firebase/firestore";
import { firebaseApp } from "./firebase";


export function usersCollection() {
    return collection(firestore(), "users");
}

export function userDocument(uid) {
    return doc(firestore(), `users/${uid}`);
}

export function userItemsCollection(uid) {
    return collection(firestore(), `users/${uid}/items`);
}

export function userItemDocument(uid, docId) {
    return doc(firestore(), `users/${uid}/items/${docId}`)
}

function firestore() {
    if (window.firestore) {
        return window.firestore;
    }

    const firestore = getFirestore(firebaseApp());
    //connectFirestoreEmulator(firestore, "localhost", 9098);

    window.firestore = firestore;

    return window.firestore;
}