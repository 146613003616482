import { INTERESTS } from "./interests";
import { SOCIAL } from "./social";

export function setupPhotoUpload(container, handler) {
    const photoImg = container.querySelector(".profile-photo-wrapper img");
    const photoInput = container.querySelector(".profile-photo-wrapper input[type='file']");
    const photoZoom = container.querySelector(".profile-photo-wrapper input[type='range']");


    const invokeHandler = () => {
        handler?.({
            file: photoInput.files[0],
            zoom: photoZoom.value
        });
    }

    photoInput.addEventListener("change", (e) => {
        const photo = e.target.files[0];
        if (photo) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                photoImg.setAttribute("src", reader.result);
                invokeHandler();
            });
                
            reader.readAsDataURL(photo);
        }
    });

    photoZoom.addEventListener("input", (e) => {
        const val = e.target.value;
        photoImg.style.width = val + "%";
        photoImg.style.height = val + "%";
    });

    photoZoom.addEventListener("change", () => invokeHandler());
}

export function renderUserInterests(container, interests) {
    container.textContent = "";

    const ids = (interests || "")
        .split(",")
        .map((id) => id.trim())
        .filter((id) => id.length > 0);
        
    ids.map((id) => INTERESTS.find((i) => i.id == id)).forEach((i) => {
        const tag = document.createElement("span");
        tag.innerHTML = "<i class='fa fa-tag'></i> " + i.name;
        tag.classList.add("user-interest-tag");

        container.appendChild(tag);
    });

    return ids;
}

export function renderUserSocial(container, social) {
    const renderSocialLink = (s, address) => {
        const icon = document.createElement("i");
        icon.classList.add("fab");
        icon.classList.add(s.icon);

        const link = document.createElement("a");
        link.target = "_blank";
        link.href = address;

        link.appendChild(icon);

        return link;
    }

    const socialSafe = social || {};
    SOCIAL
        .filter(s => socialSafe[s.name]?.trim())
        .forEach(s => container.appendChild(renderSocialLink(s, socialSafe[s.name])));
}

// ----------------------- UTILS ----------------------------
export function getQueryParamater(queryString, name) {
    return queryString.split("&").map(query => {
        const idx = query.indexOf("=");
        return {
            name: query.substring(0, idx),
            value: query.substring(idx + 1)
        };
    })
    .find(q => q.name === "q")
}



export async function blockWhile(task, message = "Please wait") {
    const block = document.querySelector("div.block");
    block.style.display = "flex";

    const msg = block.querySelector("div.message");
    msg.textContent = message;

    try {
        await task();
    } finally {
        block.style.display = "none";
    }
}

export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), timeout);
    };
}

// ----------------------- COMPONENTS ----------------------------
export function toast(message) {
    const toast = document.querySelector(".toast");
    toast.textContent = message;
    toast.classList.add("show");

    setTimeout(() => toast.classList.remove("show"), 3000);
}

let previous;
export function dropdown(dropdown) {
    const open = () => {
        if (previous) {
            previous.classList.remove("open");
        }

        previous = dropdown;

        dropdown.classList.add("open");
        document.addEventListener("click", close);
    };

    const close = () => {
        dropdown.classList.remove("open");
        document.removeEventListener("click", close);
    };

    const toggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (dropdown.classList.contains("open")) {
            close(e);
        } else {
            open(e);
        }
    };

    const trigger = dropdown.querySelector(".dropdown-trigger");
    trigger.removeEventListener("click", toggle);
    trigger.addEventListener("click", toggle);

    const content = dropdown.querySelector(".dropdown-content");
    content.removeEventListener("click", close);
    content.addEventListener("click", close);

    return dropdown;
}

export function input(options) {
    const simpleinput = document.querySelector("div.simpleinput");

    simpleinput.style.display = "flex";

    const message = simpleinput.querySelector("div.message");
    message.innerHTML = options.message || "";

    const input = simpleinput.querySelector("input");
    input.value = options.initialValue || "";

    const okHandler = () => options.okHandler?.(input.value);

    setupButtons(simpleinput, options.okLabel, okHandler, options.nokLabel, options.nokHandler);
}

export function confirm(options) {
    const confirmation = document.querySelector("div.confirmation");

    confirmation.style.display = "flex";

    const message = confirmation.querySelector("div.message");
    message.innerHTML = options.message || "";

    setupButtons(confirmation, options.okLabel, options.okHandler, options.nokLabel, options.nokHandler);
}

async function setupButtons(container, okLabel, okHandler, nokLabel, nokHandler) {
    const old_positive = container.querySelector("button.positive");
    const positive = old_positive.cloneNode(true);
    old_positive.parentNode.replaceChild(positive, old_positive);

    positive.innerHTML = "<i class='fa fa-circle-notch fa-spin'></i> " + (okLabel || "OK");
    positive.addEventListener("click", async (e) => {
        handleClick(container, e, okHandler);
    });

    const old_negative = container.querySelector("button.negative");
    const negative = old_negative.cloneNode(true);
    old_negative.parentNode.replaceChild(negative, old_negative);

    negative.textContent = nokLabel || "Cancel";
    negative.addEventListener("click", async (e) => {
        handleClick(container, e, nokHandler);
    });
}

async function handleClick(container, e, handler) {
    e.target.setAttribute("loading", "true");
    try {
        await handler?.();
    } catch (error) {
        console.error(error);
    } finally {
        e.target.setAttribute("loading", "false");
        container.style.display = "none";
    }
}
