import tpl from "./auth.html";
import "./style.css";

import {
    AuthErrorCodes,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    applyActionCode,
    verifyPasswordResetCode,
    confirmPasswordReset,
    sendPasswordResetEmail,
    signInWithRedirect,
    getRedirectResult,
    FacebookAuthProvider,
    GoogleAuthProvider,
    TwitterAuthProvider,
} from "firebase/auth";
import { 
    serverTimestamp,
    getDocs, setDoc, getDoc,
    query, where
} from "firebase/firestore";
import { debounce, setupPhotoUpload } from "../common/common";
import { auth, getCurrentUser, logout, setupUserAvatarMenu } from "../auth";
import { userDocument, usersCollection } from "../store";
import { saveUserPhoto } from "../storage";

function render() {
    return "<auth>" + tpl + "</auth>";
}

async function mount(match) {
    //handleMode(match);

    const mode = match?.params?.["mode"];
    const code = match?.params?.["oobCode"];

    if (mode == "verifyEmail") {
        handleVerifyEmail(code);
    } else if (mode == "resetPassword") {
        handleUpdatePassword(code);
    } else {
        handleLoginRedirect();
    }

    setupFormSwitch();

    setupLogin();

    setupSignup();
    setupForgot();
}

async function handleLoginRedirect() {
    switchForms(".wait")

    try {
        const result = await getRedirectResult(auth());
        
        handleLoginRedirectResult(result);
    } catch(error) {
        showAuthError("auth .wait .error", error);
    }
}

async function setupLogin() {
    // Social login
    document.querySelector("auth button.fbloginbtn").addEventListener("click", async (e) => {
        const provider = new FacebookAuthProvider();
        loginWithProvider(e, provider);
    });
    document.querySelector("auth button.gologinbtn").addEventListener("click", async (e) => {
        const provider = new GoogleAuthProvider();
        loginWithProvider(e, provider);
    });
    /*
    document.querySelector("auth button.twloginbtn").addEventListener("click", async (e) => {
        const provider = new TwitterAuthProvider();
        loginWithProvider(e, provider);
    });
    */

    setupPhotoUpload(document.querySelector("auth .login"));
    document.querySelector("auth .login input.username").addEventListener("keyup", 
        debounce((e) => checkUsername(e, ".login"))
    );
   
    // email login
    document.querySelector("auth button.loginbtn").addEventListener("click", async (e) => {
        login(e);
    });
    document.querySelector("auth button.sendverifybtn").addEventListener("click", async (e) => {
        sendVerification(e);
    });
    document.querySelector("auth input.password").addEventListener('keydown', async (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            document.querySelector("auth button.loginbtn").click();
        }
    });
}

function clearLogin() {
    document.querySelector("auth .login .email").value = "";
    document.querySelector("auth .login .password").value = "";

    document.querySelector("auth .login .initial").style.display = "block";
    document.querySelector("auth .login .notverified").style.display = "none";

    clearAuthError("auth .login .error");
}

function setupSignup() {
    setupPhotoUpload(document.querySelector("auth .signup"));
    document.querySelector("auth .signup input.username").addEventListener("keyup",
        debounce((e) => checkUsername(e, ".signup"))
    );
    
    document.querySelector("auth button.signupbtn").addEventListener("click", async (e) => {
        signup(e);
    });    
}

function clearSignup() {
    document.querySelector("auth .signup .email").value = "";
    document.querySelector("auth .signup .password").value = "";
    document.querySelector("auth .signup .password2").value = "";

    document.querySelector("auth .signup .username").value = "";

    document.querySelector("auth .signup .profile-photo-wrapper input[type='file']").value = "";
    document.querySelector("auth .signup .profile-photo-wrapper input[type='range']").value = "100";
    const photoImg = document.querySelector("auth .signup .profile-photo-wrapper img");
    photoImg.src = "../img/profile.png";
    photoImg.style.width = "100%";
    photoImg.style.height = "100%";

    document.querySelector("auth .signup .initial").style.display = "block";
    document.querySelector("auth .signup .mailsent").style.display = "none";

    clearAuthError("auth .signup .error");
}

function setupForgot() {
    document.querySelector("auth button.forgotbtn").addEventListener("click", async (e) => {
        forgot(e);
    });
}

function clearForgot() {
    document.querySelector("auth .forgot .email").value = "";

    document.querySelector("auth .forgot .initial").style.display = "block";
    document.querySelector("auth .forgot .mailsent").style.display = "none";

    clearAuthError("auth .forgot .error");
}

function setupUpdate(code) {
    document.querySelector("auth button.updatebtn").addEventListener("click", async (e) => {
        updatePassword(e, code);
    });
}

function setupFormSwitch() {
    document.querySelector("auth a.tosignup").addEventListener("click", async (e) => {
        e.preventDefault();
        clearSignup();
        switchForms(".signup");
    });

    document.querySelector("auth a.toforgot").addEventListener("click", async (e) => {
        e.preventDefault();
        clearForgot();
        switchForms(".forgot");
    });

    document.querySelectorAll("auth .tologin").forEach(link => 
        link.addEventListener("click", async (e) => {
            e.preventDefault();
            clearLogin();
            switchForms(".login");
        })
    );

    document.querySelectorAll("auth .fa-eye").forEach((e) =>
        e.addEventListener("click", async (e) => {
            const pass = e.target.parentElement.querySelector("[type='text'], input[type='password']");
            if (pass.type == "text") {
                pass.type = "password";
            } else {
                pass.type = "text";
            }
        })
    );
}

async function checkUsername(e, selector) {
    e.target.parentElement.setAttribute("loading", "true");

    const username = e.target.value.trim();

    const info = document.querySelector("auth " + selector + " div.username-info");
    info.classList.remove("taken");
    info.classList.remove("available");

    try {
        if (username) {
            const q = query(
                usersCollection(),
                where('username', '==', username),
            );
            const snapshot = await getDocs(q);
            if (snapshot.size > 0) {
                info.classList.add("taken");
            } else {
                info.classList.add("available");
            }
        }
    } finally {
        e.target.parentElement.setAttribute("loading", "false");
    }
}

function switchForms(formClass) {
    document.querySelector("auth div.login").style.display = "none";
    document.querySelector("auth div.signup").style.display = "none";
    document.querySelector("auth div.forgot").style.display = "none";
    document.querySelector("auth div.update").style.display = "none";
    document.querySelector("auth div.verify").style.display = "none";
    document.querySelector("auth div.wait").style.display = "none";

    if (formClass) {
        document.querySelector("auth div" + formClass).style.display = "block";
    }
}

async function login(e) {
    e.target.setAttribute("loading", "true");

    const email = document.querySelector("auth .login .email").value;
    const pass = document.querySelector("auth .login .password").value;

    try {
        if (!email || !pass) {
            throw Error("Email and password can not be empty");
        }
        
        clearAuthError("auth .login .error");
        await logout(false);
        
        const creds = await signInWithEmailAndPassword(auth(), email, pass);
        if (!creds.user.emailVerified) {
            document.querySelector("auth .login .initial").style.display = "none";
            document.querySelector("auth .login .notverified").style.display = "block";

            await logout(false);
        } else {
            router.navigate("/home");
        }
    } catch (error) {
        showAuthError("auth .login .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function loginWithProvider(e, provider) {
    e.target.setAttribute("loading", "true");

    try {
        clearAuthError("auth .login .error");
        await logout(false);

        const creds = await signInWithRedirect(auth(), provider);

        
    } catch(error) {
        showAuthError("auth .login .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function handleLoginRedirectResult(result) {
    
    let user;
    if (result) {
        user = result.user;
    } else {
        user = await getCurrentUser();
    }

    if (!user) {
        switchForms(".login");
        return;
    }

    const ref = await getDoc(userDocument(user.uid));

    if (ref.exists()) {
        router.navigate("/home");
    } else {
        document.querySelector("auth .login .initial").style.display = "none";
        document.querySelector("auth .login .socialsignup").style.display = "block";
        switchForms(".login");

        const listener = async (e) => socialSignupAndLogin(e, user);
        document.querySelector("auth button.socialsignupbtn").removeEventListener("click", listener);
        document.querySelector("auth button.socialsignupbtn").addEventListener("click", listener);
    }
}

async function socialSignupAndLogin(e, user) {
    e.target.setAttribute("loading", "true");

    const username = document.querySelector("auth .login .username").value;

    const photo = document.querySelector("auth .login .profile-photo-wrapper input[type='file']").files[0];
    const photoZoom = document.querySelector("auth .login .profile-photo-wrapper input[type='range']").value;

    try {
        if (!username) {
            throw Error("Username can not be empty");
        }

        await addUser(user, username, photo, photoZoom);
        await setupUserAvatarMenu(user);

        router.navigate("/home");
    } catch(error) {
        showAuthError("auth .login .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function signup(e) {
    e.target.setAttribute("loading", "true");

    clearAuthError("auth .signup .error");

    const email = document.querySelector("auth .signup .email").value;
    const pass = document.querySelector("auth .signup .password").value;
    const pass2 = document.querySelector("auth .signup .password2").value;

    const username = document.querySelector("auth .signup .username").value;

    const photo = document.querySelector("auth .signup .profile-photo-wrapper input[type='file']").files[0];
    const photoZoom = document.querySelector("auth .signup .profile-photo-wrapper input[type='range']").value;
    
    try {
        if (!username || !email) {
            throw Error("Username and email can not be empty");
        }
    
        if (!pass || !pass2) {
            throw Error("Passwords can not be empty");
        }
    
        if (pass != pass2) {
            throw Error("Passwords do not match");
        }
    
        const creds = await createUserWithEmailAndPassword(auth(), email, pass);
        await sendEmailVerification(await getCurrentUser());
        await addUser(creds.user, username, photo, photoZoom);

        document.querySelector("auth .signup .initial").style.display = "none";
        document.querySelector("auth .signup .mailsent").style.display = "block";

        await logout(false);
    } catch (error) {
        showAuthError("auth .signup .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function sendVerification(e) {
    e.target.setAttribute("loading", "true");

    clearAuthError("auth .login .error");

    try {
        await sendEmailVerification(await getCurrentUser());

        document.querySelector("auth .login .notverified").style.display = "none";
        document.querySelector("auth .login .mailsent").style.display = "block";
    } catch(error) {
        showAuthError("auth .login .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function forgot(e) {
    e.target.setAttribute("loading", "true");

    clearAuthError("auth .forgot .error");

    const email = document.querySelector("auth .forgot .email").value;

    try {
        if (!email) {
            throw Error("Email can not be empty");
        }
    
        await sendPasswordResetEmail(auth(), email);
        
        document.querySelector("auth .forgot .initial").style.display = "none";
        document.querySelector("auth .forgot .mailsent").style.display = "block";
    } catch (error) {
        showAuthError("auth .forgot .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function updatePassword(e, code) {
    e.target.setAttribute("loading", "true");

    clearAuthError("auth .update .error");

    const pass = document.querySelector("auth .update .password").value;
    const pass2 = document.querySelector("auth .update .password2").value;

    try {
        if (!pass || !pass2) {
            throw Error("Passwords can not be empty");
        }
    
        if (pass != pass2) {
            throw Error("Passwords do not match");
        }
    
        await confirmPasswordReset(auth(), code, pass);

        document.querySelector("auth .update .initial").style.display = "none";
        document.querySelector("auth .update .updated").style.display = "block";
    } catch (error) {
        showAuthError("auth .update .error", error);
    } finally {
        e.target.setAttribute("loading", "false");
    }
}

async function handleVerifyEmail(code) {
    switchForms(".wait");

    try {
        await applyActionCode(auth(), code);
    } catch (error) {
        showAuthError("auth .wait .error", error);
        if (error.code == AuthErrorCodes.EXPIRED_OOB_CODE) {
            document.querySelector(".wait .resend").style.display = "block";
        }
        return;
    }

    switchForms(".verify")
}

async function handleUpdatePassword(code) {
    switchForms(".wait");

    try {
        await verifyPasswordResetCode(auth(), code);
    } catch (error) {
        showAuthError("auth .wait .error", error)
        if (error.code == AuthErrorCodes.EXPIRED_OOB_CODE) {
            document.querySelector(".wait .resend").style.display = "block";
        }
        return;
    }

    setupUpdate(code);
    switchForms(".update")

}

function showAuthError(selector, error) {
    const div = document.querySelector(selector);
    div.style.visibility = "visible";

    let message = error.message;
    if (error.code == AuthErrorCodes.INVALID_PASSWORD) {
        message = "Wrong password";
    } else if (error.code == AuthErrorCodes.EMAIL_EXISTS) {
        message = "Email is in use";
    } else if (error.code == "auth/user-not-found") {
        message = "No such user";
    } else if (error.code == AuthErrorCodes.INVALID_OOB_CODE) {
        message = "Invalid code";
    } else if (error.code == AuthErrorCodes.EXPIRED_OOB_CODE) {
        message = "Expired code";
    }
    div.textContent = message;
}

function clearAuthError(selector) {
    const div = document.querySelector(selector);
    div.style.visibility = "hidden";
}

async function addUser(user, username, photoFile, photoZoom) {
    await setDoc(userDocument(user.uid) , {
        username: username,
        created: serverTimestamp()
    });

    if (photoFile) {
        await saveUserPhoto(user, photoFile, photoZoom);
    }
}

export { render, mount };
