import Navigo from "navigo";

import { dropdown } from "./common/common.js";
import { getCurrentUser, logout } from "./auth.js";

import * as home from "./home/home.js";
import * as privacy from "./legal/privacy.js";
import * as terms from "./legal/terms.js";
import * as auth from "./auth/auth.js";
import * as items from "./items/items.js";
import * as profile from "./profile/profile.js";
import * as users from "./users/users.js";

import "./css/style.css";
//import "./css/timeline.css";

document.addEventListener("DOMContentLoaded", (event) => {
    setupRouter();
    setupMenu();
});

const setupRouter = () => {
    const router = new Navigo("/");
    const render = (content) => (document.querySelector(".main").innerHTML = content);

    router.hooks({
        async before(done, match) {
            await getCurrentUser();
            done();
        }        
    });

    router
        .on("/home", (match) => render(home.render()), {
            after: (match) => home.mount(),
        })
        .on("/privacy", (match) => render(privacy.render()), {
            after: (match) => privacy.mount(),
        })
        .on("/terms", (match) => render(terms.render()), {
            after: (match) => terms.mount(),
        })
        .on("/auth", (match) => render(auth.render()), {
            after: (match) => auth.mount(match),
        })
        .on("/items", (match) => render(items.render()), {
            before: (done, match) => { done() }, 
            after: (match) => items.mount(),
        })
        .on("/profile", (match) => render(profile.render()), {
            after: (match) => profile.mount(),
        })
        .on("/users", (match) => render(users.render()), {
            after: (match) => users.mount(match),
        })
        .on("/:username", (match) => render(items.render(match.data.username)), {
            after: (match) => items.mount(match.data.username, match.params),
        })
        .on(() => render(home.render()), {
            after: (match) => home.mount()
        });

    const href = window.location.href;
    const idx = href.lastIndexOf("/");
    
    let route = "/home";
    if (idx > 3) {
        route = href.substring(idx + 1);
    }

    router.navigate(route);

    window.router = router;
}

const setupMenu = () => {
    const menu = dropdown(document.querySelector(".avatar-menu"));
    menu.querySelectorAll("div[data-route]").forEach(link => {
        link.addEventListener("click", async (e) => {
            router.navigate(link.getAttribute("data-route"));
        })
    });
    menu.querySelector(".logout").addEventListener("click", async () => {
        logout();
    });
}