export const DEFAULT = {
    name: "default",
    background: "--background-color",
    color: "default",
    linkRadius: ".5rem",
    linkBackground: "#fff",
    linkColor: "default",
    linkImageRadius: ".35rem"
}

export const ABSTRACT1 = {
    name: "abstract1",
    background: "url('../img/theme/abstract1.webp')",
    color: "#fff",
    linkRadius: "1rem",
    linkBackground: "#ddddddbb",
    linkColor: "#222",
    linkImageRadius: ".35rem"
}

export const ABSTRACT2 = {
    name: "abstract2",
    background: "url('../img/theme/abstract2.webp')",
    color: "#222",
    linkRadius: "1rem",
    linkBackground: "#444444bb",
    linkColor: "#ddd",
    linkImageRadius: ".35rem"
}

export const ABSTRACT3 = {
    name: "abstract3",
    background: "url('../img/theme/abstract3.webp')",
    color: "#fff",
    linkRadius: "20px",
    linkBackground: "#ffffffbb",
    linkColor: "#222",
    linkImageRadius: "0"
}

export const MONO1 = {
    name: "mono1",
    background: "#333340",
    color: "#fff",
    linkRadius: "0",
    linkBackground: "#fff",
    linkColor: "#222",
    linkImageRadius: "0"
}

export const MONO2 = {
    name: "mono2",
    background: "#d9d9d9",
    color: "#363430",
    linkRadius: "10px",
    linkBackground: "#35312e",
    linkColor: "#e2dfdd",
    linkImageRadius: "0"
}

export const MONO3 = {
    name: "mono3",
    background: "#c3d8d8",
    color: "#2a3c4d",
    linkRadius: "30px",
    linkBackground: "#8c1323",
    linkColor: "#f6e3e3",
    linkImageRadius: "15px"
}

export const THEMES = [
    DEFAULT,
    ABSTRACT1,
    ABSTRACT2,
    ABSTRACT3,
    MONO1,
    MONO2,
    MONO3
];

export function applyTheme(theme) {
    document.body.style.background = theme.background;
    document.body.style.backgroundSize = "cover";

    const main = document.querySelector(".main");
    main.style.color = theme.color;
    main.style.marginTop = "0";

    const items = main.querySelector("items");

    items.style.setProperty("--link-background", theme.linkBackground);
    items.style.setProperty("--link-color", theme.linkColor);
    items.style.setProperty("--link-border-radius", theme.linkRadius);
    items.style.setProperty("--link-image-border-radius", theme.linkImageRadius);

}