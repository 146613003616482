export const INTERESTS = [
    {id:"01", name:"Automotive"},
    {id:"02", name:"Beauty"},
    {id:"03", name:"Books and Literature"},
    {id:"04", name:"Business"},
    {id:"05", name:"Careers"},
    {id:"06", name:"Education"},
    {id:"07", name:"Events"},
    {id:"08", name:"Family and Parenting"},
    {id:"09", name:"Food and Drink"},
    {id:"10", name:"Gaming"},
    {id:"11", name:"Health"},
    {id:"12", name:"Hobbies and Interests"},
    {id:"13", name:"Home and Garden"},
    {id:"14", name:"Law, Government, and Politics"},
    {id:"15", name:"Life Stages"},
    {id:"16", name:"Movies and Television"},
    {id:"17", name:"Music and Radio"},
    {id:"18", name:"Personal Finance"},
    {id:"19", name:"Pets"},
    {id:"20", name:"Science"},
    {id:"21", name:"Society"},
    {id:"22", name:"Sports"},
    {id:"23", name:"Style and Fashion"},
    {id:"24", name:"Technology and Computing"},
    {id:"25", name:"Travel"},
];