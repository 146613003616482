import { serverTimestamp } from "firebase/firestore";
import { debounce, dropdown, input } from "../common/common";
import { callScrapeImages } from "../functions";

export async function showItemDialog(title, saveLabel, item, handler) {
    const old_popup = document.querySelector("items .overlay.newitem");
    const popup = old_popup.cloneNode(true);
    old_popup.parentNode.replaceChild(popup, old_popup);

    popup.querySelector("a.close").addEventListener("click", () => {
        popup.style.display = "none";
    });
    popup.querySelector("button.cancel").addEventListener("click", () => {
        popup.style.display = "none";
    });

    setupImageEditing(popup);

    const h2 = popup.querySelector("h2");
    h2.textContent = title;

    const name = popup.querySelector("input[name='name']");
    const link = popup.querySelector("input[name='link']");
    const desc = popup.querySelector("textarea[name='desc']");
    const image = popup.querySelector("img[name='image']");

    name.value = item?.name || "";
    link.value = item?.link || "",
    image.src = item?.image || "img/placeholder.png";
    desc.value = item?.desc || "";
    setupTagsEditing(popup, item?.tags || "");
    
    const save = popup.querySelector("button.save-item");
    save.innerHTML = "<i class='fa fa-circle-notch fa-spin'></i> " + (saveLabel || "Save");
    save.addEventListener("click", async (e) => {
        e.target.setAttribute("loading", "true");
    
        const editeditem = {
            name: name.value,
            link: link.value,
            image: image.src,
            desc: desc.value,
            tags: getTags(popup),
            created: item?.created || serverTimestamp()
        };
 
        try {
            await handler(editeditem, item)
            popup.style.display = "none";
        } catch(error) {
            console.log(error)
        } finally {
            e.target.setAttribute("loading", "false");
        }
    });

    popup.style.display = "block";
}

function setupTagsEditing(popup, itemTags) {
    const tags = popup.querySelector(".tags");
    const input = tags.querySelector(".tags-input");

    // remove all tags
    tags.querySelectorAll(".tag").forEach(t => tags.removeChild(t));

    const renderTag = (tagTxt) => {
        const tag = document.createElement("span");
        tag.classList.add("tag");

        const txt = document.createTextNode(tagTxt);
        tag.appendChild(txt);

        const close = document.createElement("i");
        close.classList.add("fa", "fa-times");
        close.style.marginLeft = ".25rem";
        close.style.cursor = "pointer";
        close.addEventListener("click", () => tags.removeChild(tag));
        tag.appendChild(close);

        return tag;
    }

    // render initial tags
    itemTags.split(",")
        .filter(t => t)
        .forEach(txt => tags.insertBefore(renderTag(txt), input))

    const lastTag = () => tags.querySelector(".tag:last-of-type");

    if (lastTag()) {
        input.style.display = "none";
    } else {
        input.style.display = "initial";
    }

    tags.addEventListener("click", () => {
        tags.classList.add("focus");
        input.style.display = "inline";
        input.focus();
    });

    input.addEventListener("blur",()=>{
        input.style.display = "none";
        tags.classList.remove("focus");
    });

    input.addEventListener("keydown", (e) => {
        if (e.keyCode === 13 && input.value.length > 0) {
            tags.insertBefore(renderTag(input.value), input);
            input.value = "";
        } else if (e.keyCode === 8 && input.selectionEnd == 0) {
            const last = lastTag();
            last && tags.removeChild(last);
        }
    });
}

function getTags(popup) {
    const tags = popup.querySelector(".tags");
    return Array.from(tags.querySelectorAll(".tag"))
        .map(tag => tag.innerText)
        .join(",");
}

function setupImageEditing(popup) {
    const link = popup.querySelector("input[name='link']");
    const imgDiv = popup.querySelector("div.images");
    const imgElm = imgDiv.querySelector("img.image");

    let imgs = null;

    const showPickImages = async () => {
        if (!imgs) {
            imgs = await fetchScrapedImages(link.value.trim());
        }

        showPickImageDialog(imgs, imgElm.src, img => imgElm.src = img.src);
    }

    const imgMenu = dropdown(popup.querySelector(".image-menu"));
    imgElm.addEventListener("click", showPickImages);
    imgMenu.querySelector(".pick").addEventListener("click", showPickImages);
    imgMenu.querySelector(".enter").addEventListener("click", () => {
        input({
            message: "Image URL name",
            initialValue: imgElm.src,
            okHandler: (value) => imgElm.src = value
        });
    });

    const clearImage = () => {
        imgDiv.querySelector("img").src = "img/placeholder.png";
        const info = imgDiv.querySelector("span.image-info");
        if (info) {
            imgDiv.removeChild(info);
        }
    }
    
    const startAnimation = () => {
        imgDiv.classList.add("scraping");
    }

    const stopAnimation = () => {
        imgDiv.classList.remove("scraping");
    }

    const fetchScrapedImages = async (url) => {
        startAnimation();
        try {
            const res = await callScrapeImages(url);
            return res.data.filter(
                img => (img.wd > 64 && img.ht > 64)
            );
        } finally {
            stopAnimation();
        }
    }

    link.addEventListener("input", debounce(async (e) => {
        stopAnimation();

        const url = link.value.trim();
        if (url) {
            imgs = await fetchScrapedImages(url);

            if (imgs && imgs.length > 0) {
                imgElm.src = imgs[0].src;
            } else {
                clearImage();
            }
        } else {
            stopAnimation();
            clearImage();
        }
    }));
}

function showPickImageDialog(imgs, selectedSrc, handler) {
    const old_popup = document.querySelector("#pickimage");
    const popup = old_popup.cloneNode(true);
    old_popup.parentNode.replaceChild(popup, old_popup);

    const content = popup.querySelector(".content");

    const closePopup = () => {
        content.textContent = '';
        popup.style.display = "none";
    }
    
    popup.querySelector("a.close").addEventListener("click", () => closePopup());

    const onImgSelected = (img) => {
        try {
            handler(img);
        } finally {
            closePopup();
        }
    }

    const createImg = (img) => {
        const imgElm = document.createElement("img");

        imgElm.classList.add("image");
        if (img.src === selectedSrc) {
            imgElm.classList.add("selected");
        }

        imgElm.src = img.src;
        imgElm.addEventListener('load', () => onImgLoaded(imgElm));
        imgElm.addEventListener('click', () => onImgSelected(imgElm));

        const divElm = document.createElement("div");
        divElm.classList.add("d-flex", "p-relative");
        divElm.appendChild(imgElm);

        content.appendChild(divElm);
    }
    
    imgs.forEach(img => createImg(img))
    
    popup.style.display = "block";
}

const onImgLoaded = (imgElm) => {
    const parent = imgElm.parentElement;

    let info = parent.querySelector("span.image-info");
    if (!info) {
        info = document.createElement("span");
        info.classList.add("image-info");
        parent.appendChild(info);
    }

    if (imgElm.naturalWidth < 64 && imgElm.naturalHeight < 64) {
        parent.removeChild(imgElm.parentElement);
    } else {
        info.textContent = `${imgElm.naturalWidth} x ${imgElm.naturalHeight}px`;
    }
}
