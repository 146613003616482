import tpl from "./users.html";
import "./users.style.css";

import { getDocs, query, where } from "firebase/firestore";
import { usersCollection } from "../store";
import { render as renderUser } from "./user";
import { getQueryParamater } from "../common/common";

const users = [];

function render() {
    return "<users>" + tpl + "</users>";
}

async function mount(match) {
    const queryText = getQueryParamater(match.queryString, "q").value
    await search(queryText);
}

async function search(queryText) {
    const q = query(
        usersCollection(),
        where('username', '>=', queryText),
        where('username', '<=', queryText+ '\uf8ff')
    );
    
    users.length = 0;

    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        users.push({id: doc.id, ...doc.data()});
    });

    renderUsers();
}

function renderUsers() {
    const container = document.querySelector("users .pwrapper .results");
    container.textContent = "";

    users.forEach(async user => container.appendChild(await renderUser(user, true)));
}

export {render, mount};