import tpl from "./profile.html";
import "./style.css";

import { getDoc, updateDoc } from "firebase/firestore";
import { createUserProfileElm, getCurrentUser, setupUserAvatarMenu } from "../auth";
import { userDocument } from "../store";
import { INTERESTS } from "../common/interests";
import { renderUserInterests, setupPhotoUpload } from "../common/common";
import { saveUserPhoto } from "../storage";

let user;

function render() {
    return "<profile>" + tpl + "</profile>";
}

async function mount() {
    document.querySelectorAll("profile [loading]").forEach(elm => elm.setAttribute("loading", true));
    user = await getCurrentUser();

    await renderUserProfile();
}

async function renderUserProfile() {
    const ref = await getDoc(userDocument(user.uid));

    const userData = ref.data();
    
    renderGDLink(userData);

    try {
        renderBio(userData);
        renderInterests(userData);
        renderSocial(userData);
    } finally {
        document.querySelectorAll("profile [loading]").forEach(elm => elm.setAttribute("loading", false));
    }
    
}

function renderGDLink(userData) {
    const gdlink = window.location.origin + "/" + userData.username;
    document.querySelector("profile input.gd-link").value = gdlink;
    document.querySelector("profile .gd-link-container .copy").addEventListener("click", () => navigator.clipboard.writeText(gdlink));
}

// ============================ BIO ============================ //
async function renderBio(userData) {
    // photo
    const elm = await createUserProfileElm(user);

    const photoElm = document.querySelector("profile .bio-container .profile-photo-wrapper .img-container img");
    photoElm.src = elm.src;
    photoElm.style.width = elm.style.width;
    photoElm.style.height = elm.style.height;

    const photoZoom = document.querySelector("profile .bio-container .profile-photo-wrapper input[type='range']");
    photoZoom.value = elm.style.width.slice(0, -1);

    setupPhotoUpload(
        document.querySelector("profile .bio-container"),
        async (data) => {
            await saveUserPhoto(user, data.file, data.zoom);
            await setupUserAvatarMenu(user);
        }
    );

    document.querySelector("profile .bio-container textarea.bio-input").value = userData.bio || "";

    const container = document.querySelector("profile .bio-container [editing]");
    setupInlineEditing(container, "textarea", async () => await updateBio())
}

async function updateBio() {
    const container = document.querySelector("profile .bio-container");
    container.querySelector("h4[loading]").setAttribute("loading", true);
    try {
        const bio = container.querySelector("textarea.bio-input").value;
        await updateDoc(userDocument(user.uid), {bio: bio});
    } finally {
        container.querySelector("h4[loading]").setAttribute("loading", false);
    }
}

// ============================ INTERESTS ============================ //
function renderInterests(userData) {
    const container = document.querySelector("profile .interests-container div.user-interests");
    container.setAttribute("interests", userData.interests);

    let ids = renderUserInterests(container, userData.interests);

    document.querySelector("profile .edit-interests-button").addEventListener("click", () => {
        showInterestsDialog(ids, async (updatedIds) => {
            await updateInterests(updatedIds);
            ids = renderUserInterests(container, updatedIds);
        });
    });
}

async function updateInterests(updatedIds) {
    const container = document.querySelector("profile .interests-container");
    container.querySelector("h4[loading]").setAttribute("loading", true);
    try {
        await updateDoc(userDocument(user.uid), {interests: updatedIds});
    } finally {
        container.querySelector("h4[loading]").setAttribute("loading", false);
    }
}

function showInterestsDialog(ids, onOk) {
    const old_popup = document.querySelector("profile #interests");
    const popup = old_popup.cloneNode(true);
    old_popup.parentNode.replaceChild(popup, old_popup);

    const content = popup.querySelector(".content");

    const updateCount = () => {
        popup.querySelector("span.selected-count").textContent = getAllChecked(popup).length;
    }

    INTERESTS.forEach((i) => {
        content.appendChild(createCheckbox(i, ids, (cb) => {
            if (getAllChecked(popup).length > 5) {
                cb.checked = false;
            }
            updateCount();
        }));
    });

    const closePopup = () => {
        content.textContent = "";
        popup.style.display = "none";
    };

    popup.querySelector("a.close").addEventListener("click", () => closePopup());
    popup.querySelector("button.cancel").addEventListener("click", () => closePopup());

    popup.querySelector("button.ok").addEventListener("click", () => {
        try {
            const updateIds = getAllChecked(popup)
                .map((cb) => cb.id)
                .join(",");
            onOk?.(updateIds);
        } finally {
            closePopup();
        }
    });

    updateCount();
    popup.style.display = "block";
}

function createCheckbox(i, ids, onChange) {
    const checkbox = document.createElement("label");
    checkbox.classList.add("checkbox");

    const label = document.createTextNode(i.name);
    checkbox.appendChild(label);

    const input = document.createElement("input");
    input.type = "checkbox";
    input.checked = ids.includes(i.id);
    input.id = i.id;
    input.value = i.id;
    input.addEventListener("change", () => onChange(input));
    checkbox.appendChild(input);

    const checkmark = document.createElement("span");
    checkmark.classList.add("checkmark");
    checkbox.appendChild(checkmark);

    const div = document.createElement("div");
    div.classList.add("interest");
    div.appendChild(checkbox);

    return div;
}

function getAllChecked(popup) {
    return Array.from(popup.querySelectorAll("input[type=checkbox]")).filter((cb) => cb.checked);
}

// ============================ SOCIAL ============================ //
function renderSocial(userData) {
    document.querySelectorAll("profile .social-container [editing]").forEach(container => {
        setupInlineEditing(container, "input", async () => await updateSocial())
    });

    Object.keys(userData.social || {}).forEach((s) => {
        document.querySelector(`profile .social-input[social="${s}"]`).value = userData.social[s];
    });
}

async function updateSocial() {
    const container = document.querySelector("profile .social-container");
    container.querySelector("h4[loading]").setAttribute("loading", true);
    try {
        const social = {};
        container.querySelectorAll("profile input.social-input").forEach(input => {
            const socialAttr = input.getAttribute("social");
            social[socialAttr] = input.value;
        });

        await updateDoc(userDocument(user.uid), {social: social});

        console.log(social);
    } finally {
        container.querySelector("h4[loading]").setAttribute("loading", false);
    }
}

function setupInlineEditing(container, editor, onOk) {
    const input = container.querySelector(editor);

    container.querySelector(".edit").addEventListener("click", () => {
        input.setAttribute("original", input.value);
        input.disabled = false;
        input.focus();
        container.setAttribute("editing", true);
        
    });

    container.querySelector(".ok").addEventListener("click", async () =>  {
        container.setAttribute("editing", false);
        input.disabled = true;
        if (input.getAttribute("original") != input.value) {
            onOk?.();
        }
    });
    container.querySelector(".nok").addEventListener("click", () => {
        input.value = input.getAttribute("original");
        container.setAttribute("editing", false);
        input.disabled = true;
    });
}

export { render, mount };
