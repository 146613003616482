import tpl from "./items.html";
import "./style.css";
import "./preview.css";

import Sortable from 'sortablejs';
import {
    getDoc, getDocs, addDoc, updateDoc, deleteDoc,
    query, where, orderBy,
    serverTimestamp,
} from "firebase/firestore";
import { blockWhile, confirm, debounce, dropdown, input, renderUserSocial, toast } from "../common/common";
import { createUserProfileElm, getCurrentUser } from "../auth";
import { userDocument, userItemDocument, userItemsCollection, usersCollection } from "../store";
import { showItemDialog } from "./item-dialog";
import { applyTheme, THEMES } from "../common/theme";

const allItems = [];
const items = [];

let userUid = null;
let userData = null;
let preview = false;

let insearch = false;

function render(username) {
    return "<items>" + tpl + "</items>";
}

async function mount(username, params) {
    userData = await fetchUserData(username);
    if (userData) {
        userUid = userData.uid;
    } else {
        return;
    }

    checkPreviewMode();
    setupSearch();

    if (username) {
        await setupViewMode(username, params);
    } else {
        await setupEditMode();
    }
 
    await fetchAllItems();
    renderItems();
}

function checkPreviewMode() {
    if (window.self != window.top) {
        preview = true;
        document.querySelector(".header .avatar-menu").style.visibility = "hidden";
        document.querySelector(".header .logo").addEventListener("click", (e) => e.preventDefault());
    }

}

function setupSearch() {
    const old_search = document.querySelector("input.main-search-input");
    var search = old_search.cloneNode(true);
    old_search.parentNode.replaceChild(search, old_search);

    const clearSearch = (e) => {
        search.value = "";
        searchItems();
    }

    search.parentNode.querySelector(".fa-times").addEventListener("click", clearSearch);
    document.querySelector("items .clear-search-button").addEventListener("click", clearSearch);

    search.setAttribute("placeholder", "Search for links");
    search.addEventListener('keyup', debounce(() => searchItems()));
}

async function searchItems() {
    const input = document.querySelector("input.main-search-input");
    const queryText = input.value;
    input.parentElement.setAttribute("loading", "true");

    try {
        if (queryText) {
            const query = queryText.toLocaleLowerCase();
            
            items.length = 0;
            items.push(...allItems.filter(item => {
                return item.name?.toLocaleLowerCase().includes(query) || 
                item.desc?.toLocaleLowerCase().includes(query);
            }));

            input.parentElement.setAttribute("search", "true");
            insearch = true;

            document.querySelector("items .edit-buttons").classList.add("insearch");
        } else {
            //await fetchAllItems();
            items.length = 0;
            items.push(...allItems);

            input.parentElement.setAttribute("search", "false");
            insearch = false;

            document.querySelector("items .edit-buttons").classList.remove("insearch");
        }
        
        renderItems(true);
    } catch(error) {
        console.log(error);
    } finally {
        input.parentElement.setAttribute("loading", "false");
    }
}

function checkNotInsearch() {
    if (insearch) {
        toast("Clear search to reorder items");
        const button = document.querySelector("items .clear-search-button");
        button.style.animation = "pulse 2s infinite";
        setTimeout(() => button.style.animation = "none", 4000);
        return false;
    }
    return true;
}

async function fetchUserData(username) {
    if (username) {
        const q = query(
            usersCollection(),
            where('username', '==', username),
        );
        const snapshot = await getDocs(q);
        if (snapshot.size == 0) {
            // TODO: display 'user not found' ?
            window.router.navigate("/home");
            return null;
        }

        const userRef = snapshot.docs[0];
        //userUid = userRef.id;
        return {uid: userRef.id,  ...userRef.data()};
    } else {
        const currentUser = await getCurrentUser();
        if (currentUser) {
            //userUid = auth().currentUser.uid;

            const userRef = await getDoc(userDocument(currentUser.uid));
            return {uid: currentUser.uid,  ...userRef.data()};
            
        } else {
            // TODO: display 'login' ?
            window.router.navigate("/home");
            return null;
        }
    }
    
}

async function setupViewMode(username, params) {
    let themeName = null
    if (params?.theme) {
        themeName = params.theme;
    }
    if (!themeName) {
        themeName = userData.theme;
    }
    if (!themeName) {
        themeName = "default";
    }

    const theme = THEMES.find(t => t.name === themeName);
    if (theme) {
        applyTheme(theme)
    }

    document.querySelector(".header").style.display = "none";
    renderUserInfo(userData);
}

async function renderUserInfo(userData) {
    const contact = document.querySelector("items .user-contact");
    contact.style.display = "flex";
    renderUserSocial(contact, userData.social);

    const usercontainer = document.querySelector(".userdetail");
    usercontainer.style.display = "block";

    const avatar = usercontainer.querySelector(".avatar");
    avatar.appendChild(await createUserProfileElm(userData));

    const username = usercontainer.querySelector(".name");
    username.textContent = userData.username;
}

async function setupEditMode() {
    const host = document.querySelector("items");
    host.classList.add("edit");

    setupEditButtons();
    setupDrag();
    setupPreview();
    setupThemeSelection();
}

function setupThemeSelection() {
    const themes = document.querySelector("items #themes");
    themes.value = userData.theme || "default";

    themes.addEventListener("change", async e => {
        const name = e.target.value;
        const theme = THEMES.find(t => t.name === name);
        if (theme) {
            await updateDoc(userDocument(userUid), {theme: name});
            refreshPreview(name);
        }
    });
}

function setupEditButtons() {
    // add link
    document.querySelector("items .add-link-button").addEventListener("click", () => {
        showItemDialog("New link", "Add link", null, async (newitem) => {
            addItem(newitem);
        });
    });

    // add title
    document.querySelector("items .add-title-button").addEventListener("click", () => {
        input({
            message: "Title name",
            okHandler: async (value) => {
                await addItem({
                    type: "title",
                    name: value,
                    created: serverTimestamp()
                });
            }
        });
    });

    // preview
    document.querySelector("items .preview-button").addEventListener("click", () => {
        window.open(userPageURL(), '_blank').focus();
    })
}

function setupDrag() {
     Sortable.create(document.querySelector("items .items-container"), {
        dragClass: "chosen",
        ghostClass: "ghost",
        forceFallback: true,
        onEnd: async (evt) => {
            if (evt.newIndex != evt.oldIndex) {
                const temp = items[evt.oldIndex];
                items.splice(evt.oldIndex, 1);
                items.splice(evt.newIndex, 0, temp);

                await updateItemOrder();
            }
        },
        onMove: (evt) => {
            return checkNotInsearch();
         }
    });
}

function setupPreview() {
    const preview = document.querySelector("items div.preview-container");
    preview.style.display = "block";

    preview.querySelector("input").value = userPageURL();
}

function refreshPreview(theme) {
    const preview = document.querySelector("items div.items-preview-device");

    let src = userPageURL() + "?ts=" + new Date().getTime();
    if (theme) {
        src += "&theme=" + theme;
    }
    preview.querySelector("iframe").src = src;
}

function userPageURL() {
    return window.location.origin + "/" + userData.username;
}

async function fetchAllItems() {

    const userDoc = await getDoc(userDocument(userUid));
    const itemIds = userDoc.data().itemOrder?.split(",");

    const q = query(
        userItems(),
        orderBy("created", "desc"),
    );

    const snapshot = await getDocs(q);
    let fetched = [];
    snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        fetched.push({id: doc.id, ...doc.data()});
    });

    if (itemIds) {
        fetched = itemIds.map(id => fetched.find(i => i.id == id));
    }

    
    items.length = 0;
    items.push(...fetched);
    
    allItems.length = 0;
    allItems.push(...fetched);
}

function renderItems(norefresh = false) {
    const parent = document.querySelector("items .items-container");
    // clear parent before rendering
    parent.textContent = '';

    items.forEach((item) => {
        if (item.type == "title") {
            parent.appendChild(renderTitle(item));
        } else {
            parent.appendChild(renderItem(item));
        }
    });

    if (!preview && !norefresh) {
        refreshPreview();
    }
}

function renderTitle(item) {
    const template = document.querySelector("items #title");

    const clone = template.content.cloneNode(true);

    const h4 = clone.querySelector("h4");
    h4.textContent = item.name;

    // menu
    const menu = dropdown(clone.querySelector(".item-menu"));

    const editBtn = menu.querySelector(".edit");
    editBtn.addEventListener("click", () => {
        input({
            message: "Title name",
            initialValue: item.name,
            okHandler: (value) => updateItem({...item, name: value}, item)
        });
    });

    const deleteBtn = menu.querySelector(".delete");
    deleteBtn.addEventListener("click", async () => {
        confirm({
            message: "Are you sure want to delete this title ?",
            okLabel: "Delete",
            okHandler: async () => deleteItem(item)
        });
    });

    return clone;
}

function renderItem(item) {
    const template = document.querySelector("items #item");

    const clone = template.content.cloneNode(true);

    clone.querySelectorAll("a.link").forEach(link => {
        link.href = item.link
    });

    const h4 = clone.querySelector("h4");
    h4.textContent = item.name;

    const img = clone.querySelector("img");
    img.src = item.image || "img/placeholder.png";

    const desc = clone.querySelector("div.item div.desc");
    desc.textContent = item.desc;

    const tags = clone.querySelector("div.item div.tags");
    tags.textContent = "";
    (item.tags || "")
        .split(",")
        .map((t) => t.trim())
        .forEach((t) => {
            const tag = document.createElement("span");
            tag.innerHTML = "<i class='fa fa-tag'></i> " + t;
            tag.classList.add("tag");

            tags.appendChild(tag);
        });

    // menu
    const menu = dropdown(clone.querySelector(".item-menu"));

    const editBtn = menu.querySelector(".edit");
    editBtn.addEventListener("click", (e) => {
        e.preventDefault();
        showItemDialog("Edit link", "Update link", item, async (updateditem, item) => {
            updateItem(updateditem, item);
        });
    });

    const deleteBtn = menu.querySelector(".delete");
    deleteBtn.addEventListener("click", async (e) => {
        e.preventDefault();
        confirm({
            message: "Are you sure want to delete this link ?",
            okLabel: "Delete",
            okHandler: async () => deleteItem(item)
        });
    });

   
    const moveupBtn = menu.querySelector(".moveup");
    moveupBtn.addEventListener("click", async (e) => {
        e.preventDefault();
        swap(-1);
    });

    const movedownBtn = menu.querySelector(".movedown");
    movedownBtn.addEventListener("click", async (e) => {
        e.preventDefault();
        swap(1);
    });

    const swap = async (diff) => {
        if (!checkNotInsearch()) {
            return false;
        }

        const idx = items.findIndex(i => item.id === i.id);
        items[idx] = items.splice(idx + diff, 1, items[idx])[0];

        await updateItemOrder();
        renderItems();
    }

    return clone;
}

async function addItem(item) {
    blockWhile(async () => {
        const ref = await addDoc(userItems(), item);

        items.unshift({id: ref.id, ...item});
        await updateItemOrder();

        renderItems();
    }, "Saving new item");
}

async function updateItem(updateditem, item) {
    blockWhile(async () => {
        await updateDoc(userItem(item.id), updateditem);

        const idx = items.findIndex(i => i.id === item.id);
        items.splice(idx, 1, {id: item.id, ...updateditem});
        renderItems();
    }, "Saving");
}

async function deleteItem(item) {
    blockWhile(async () => {
        await deleteDoc(userItem(item.id));

        const idx = items.findIndex(i => i.id === item.id);
        items.splice(idx, 1);
        await updateItemOrder();

        renderItems();
    }, "Deleting item");
}

async function updateItemOrder() {
    blockWhile(async () => {
        const order = items.map(i => i.id).join(",");
        await updateDoc(userDocument(userUid), {itemOrder: order});
        refreshPreview();
    }, "Updating item order");
}

function userItems() {
    return userItemsCollection(userUid);
}

function userItem(id) {
    return userItemDocument(userUid, id);
}

export {render, mount};
