import { 
    getFunctions, 
    //connectFunctionsEmulator,
    httpsCallable
} from "firebase/functions";
import { firebaseApp } from "./firebase";

export function functions() {
    if (window.functions) {
        return window.functions;
    }

    const functions = getFunctions(firebaseApp());
    //connectFunctionsEmulator(functions, "localhost", 5001);

    window.functions = functions;

    return window.functions;
}

export async function callScrapeImages(url) {
    const scrapeImages = httpsCallable(functions(), "scrapeImages");
    return await scrapeImages({ url: url });
}