import userTpl from "./user.html";
import "./user.style.css";

import { renderUserInterests, renderUserSocial } from "../common/common";
import { createUserProfileElm } from "../auth";

async function render(user, showViewPage = false, renderInterests = true, renderJoinDate = true, renderBio = true) {
    //const template = document.querySelector("users #user");

    const clone = document.createElement("user");
    clone.innerHTML = userTpl;

    const avatar = clone.querySelector(".avatar");
    avatar.textContent = "";
    avatar.appendChild(await createUserProfileElm(user));

    const username = clone.querySelector(".username");
    username.textContent = user.username;

    const created = clone.querySelector(".created");
    
    if (renderJoinDate) {
        created.textContent = new Date(user.created.seconds * 1000).toLocaleDateString();    
    } else {
        created.parentElement.parentElement.removeChild(created.parentElement);
    }

    const bio = clone.querySelector(".bio");
    if (renderBio) {
        bio.textContent = user.bio;
    } else {
        bio.parentElement.removeChild(bio);
    }

    const interests = clone.querySelector(".interests");
    if (renderInterests) {
        renderUserInterests(interests, user.interests);
    } else {
        interests.parentElement.parentElement.removeChild(interests.parentElement);
    }

    const contact = clone.querySelector(".contact");
    contact.textContent = "";
    renderUserSocial(contact, user.social);

    const pagelink = clone.querySelector(".pagelink");
    if (showViewPage) {
        pagelink.querySelector("a").href = window.location.origin + "/" + user.username;
        pagelink.setAttribute("data-navigo","");
    } else {
        pagelink.style.display = "none";
    }

    return clone;
}

export { render };
