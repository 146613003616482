// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../img/profile.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../img/items.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../img/social.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div> <br/> <p>Are you a social media <b>influencer</b> with many followers ? Are you in <b>Affiliate marketing</b> ?</p> <p> <span style=\"font-size:1.1rem\">This is your Gather Day! This service is for you, <b>free</b> and easy to use.</span> </p> <br/> <p> <button class=\"start\">Lets get started</button> </p> <div class=\"steps\"> <div class=\"step\"> <div class=\"circle\">1</div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <p> Sign up and create your influencer profile. Let brands know about your influencer career. </p> <p> <i class=\"fa fa-chevron-down\"></i> </p> <p> We will create a page exclusively for you. </p> </div> <div class=\"step\"> <span class=\"circle\">2</span> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> <p> Login to your page and add your links and create your portfolio. </p> <p> <i class=\"fa fa-chevron-down\"></i> </p> <p> Your followers can easily explore and search them. </p> </div> <div class=\"step\"> <div class=\"circle\">3</div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> <p> Share your page in your social media accounts. Let your followers access your links and products. </p> <p> <i class=\"fa fa-chevron-down\"></i> </p> <p> Dont be constrained by post size or link limitations. </p> </div> </div> </div> <div class=\"text-center\"> <a href=\"/privacy\" data-navigo>Privacy Policy</a> &nbsp; | &nbsp; <a href=\"/terms\" data-navigo>Terms & Conditions</a> </div> ";
// Exports
export default code;