import { initializeApp } from "firebase/app";

export function firebaseApp() {
    if (window.firebaseApp) {
        return window.firebaseApp;
    }

    const firebaseApp = initializeApp({
        apiKey: "AIzaSyADubR4CF4NrZ16D6COX7IqB4lT8ETNvCE",
        //authDomain: "gatherday-d0f3d.firebaseapp.com",
        authDomain: "gatherday.com",
        projectId: "gatherday-d0f3d",
        storageBucket: "gatherday-d0f3d.appspot.com",
        messagingSenderId: "236733818650",
        appId: "1:236733818650:web:42a2c5bd7ccf52eafd2ea3",
    });

    window.firebaseApp = firebaseApp;
}
