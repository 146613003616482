import tpl from "./terms.html";
import "./style.css";

function render() {
    return "<terms>" + tpl + "</terms>";
}

async function mount() {
    
}

export {render, mount};