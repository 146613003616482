import tpl from "./privacy.html";
import "./style.css";

function render() {
    return "<privacy>" + tpl + "</privacy>";
}

async function mount() {
    
}

export {render, mount};